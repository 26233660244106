<template>
  <!-- 功能条 -->
  <div class="tool-view">
    <!-- 分销商： -->
    <span class="tool-title">分销商：</span>
    <a-input v-model:value="searchModel" style="width: 160px;" placeholder="请输入" />
    <!-- 操作 -->
    <a-button type="primary" style="margin-left: 16px;" @click="touchSearch">查询</a-button>
    <a-button style="margin-left: 16px;" @click="touchReset">重置</a-button>
    <!-- 弹簧 -->
    <div style="flex: 1;"></div>
    <!-- 操作 -->
    <a-button @click="touchDownload">导出数据</a-button>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="pagination"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record }">
      <!-- 分销商名称 -->
      <template v-if="column.key === 'reseller'">
        {{ record.reseller && record.reseller.name }}
      </template>
      <!-- 结算项目 -->
      <template v-if="column.key === 'project'">
        {{ record.project && record.project.name || '-' }}
      </template>
      <!-- 结算周期 -->
      <template v-if="column.key === 'cycle'">
        {{ `${record.start_time}至${record.end_time}` }}
      </template>
      <!-- 结算金额 -->
      <template v-if="column.key === 'total_cash_amount'">
        {{ $pub.KEEP_NUMBER_DECIMAL((record[column.key] || 0) / 100, 2, false, true) }}
      </template>
      <!-- 金额 -->
      <template v-if="
        column.key === 'cash_amount' ||
        column.key === 'iaa_cash_amount' ||
        column.key === 'cpm_cash_amount'
      ">
        <a @click="touchPayDetail(record, column.key)">
          {{ $pub.KEEP_NUMBER_DECIMAL((record[column.key] || 0) / 100, 2, false, true) }}
        </a>
      </template>
      <!-- 操作 -->
      <template v-if="column.key === 'operation'">
        <a class="operation-item" @click="touchPaySuccess(record)">完成打款</a>
        <a class="operation-item" @click="touchPayInfo(record)">打款信息</a>
      </template>
    </template>
  </a-table>
  <PayInfoModal ref="RefPayInfoModal"></PayInfoModal>
  <IapPayDetailDrawer ref="RefIapPayDetailDrawer"></IapPayDetailDrawer>
  <IaaPayDetailDrawer ref="RefIaaPayDetailDrawer"></IaaPayDetailDrawer>
  <CpmPayDetailDrawer ref="RefCpmPayDetailDrawer"></CpmPayDetailDrawer>
</template>

<script setup>
import { onBeforeMount, ref, reactive, defineEmits } from 'vue'
import { message } from 'ant-design-vue'
import { resellerCashList, resellerCashPay } from '@/api/copyright'
import PayInfoModal from './PayInfoModal'
import IapPayDetailDrawer from './IapPayDetailDrawer'
import IaaPayDetailDrawer from './IaaPayDetailDrawer'
import CpmPayDetailDrawer from './CpmPayDetailDrawer'
import { Modal } from 'ant-design-vue'
import Pub from '@/utils/public'
import * as Ex from "@netjoy/excelex"

// 提交事件
const emits = defineEmits(['success'])
// 打款信息
let RefPayInfoModal = ref(null)
// 短剧数据
let RefIapPayDetailDrawer = ref(null)
let RefIaaPayDetailDrawer = ref(null)
let RefCpmPayDetailDrawer = ref(null)
// 加载
let isLoading = ref(false)
// 搜索
let searchModel = ref(undefined)
let search = ref(undefined)
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '提交时间',
    dataIndex: 'created_at',
    key: 'created_at'
  },
  {
    title: '分销商名称',
    dataIndex: 'reseller',
    key: 'reseller'
  },
  // {
  //   title: '结算项目',
  //   dataIndex: 'project',
  //   key: 'project'
  // },
  {
    title: '结算周期',
    dataIndex: 'cycle',
    key: 'cycle'
  },
  {
    title: '结算金额',
    dataIndex: 'total_cash_amount',
    key: 'total_cash_amount'
  },
  {
    title: 'IAP金额',
    dataIndex: 'cash_amount',
    key: 'cash_amount'
  },
  {
    title: 'IAA金额',
    dataIndex: 'iaa_cash_amount',
    key: 'iaa_cash_amount'
  },
  {
    title: 'CPM金额',
    dataIndex: 'cpm_cash_amount',
    key: 'cpm_cash_amount'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

// 钩子函数
onBeforeMount (() => {
  // 获取推广数据列表
  getList()
})

// 打款明细
function touchPayDetail(record, key) {
  if (key === 'cash_amount') {
    RefIapPayDetailDrawer.value.showDrawer(record)
  } else {
    // iaa_cash_amount
    // RefIaaPayDetailDrawer.value.showDrawer(record)
    RefCpmPayDetailDrawer.value.showDrawer(record)
  }
}

// 打款信息
function touchPayInfo(record) {
  RefPayInfoModal.value.showModal(record)
}

// 完成打款
function touchPaySuccess(record) {
  Modal.confirm({
    title: '提示',
    content: '确认要进行该操作吗？',
    cancelText: '取 消',
    okText: '确 定',
    onOk: () => {
      paySuccess(record)
    }
  })
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 查询
function touchSearch () {
  // 调整参数
  search.value = searchModel.value
  pagination.current = 1
  // 获取列表
  getList()
}

// 重置
function touchReset () {
  searchModel.value = undefined
  search.value = undefined
  // 初始化获取
  touchSearch()
}

// 获取推广数据列表
function getList () {
  isLoading.value = true
  const params = {
    kw: search.value,
    status: 0,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  resellerCashList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 完成打款
function paySuccess (record) {
  isLoading.value = true
  const params = {
    id: record
  }
  resellerCashPay(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      message.success('打款成功')
      touchSearch()
      emits('success')
    } else {
      message.error(res.message || msg)
    }
  })
}

// 点击导出数据
function touchDownload () {
  isLoading.value = true
  const params = {
    kw: search.value,
    status: 0,
    page: 1,
    page_size: 10000
  }
  resellerCashList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      let list = data.data 
      if (list.length) {
        const columns = [
          {
            name: '提交时间',
            field: 'created_at'
          },
          {
            name: '分销商名称',
            field: 'reseller'
          },
          // {
          //   name: '结算项目',
          //   field: 'project'
          // },
          {
            name: '结算周期',
            field: 'cycle'
          },
          {
            name: '结算金额',
            field: 'total_cash_amount'
          },
          {
            name: 'IAP金额',
            field: 'cash_amount'
          },
          {
            name: 'IAA金额',
            field: 'iaa_cash_amount'
          },
          {
            name: 'CPM金额',
            field: 'cpm_cash_amount'
          }
        ]
        const sheets = [
          {
            data: list,
            columns: columns
          }
        ]
        Ex.ex(sheets, function (item, field, json, sheetIndex, row, col, rowCount, colCount) {
          // row=0 为表头
          if (row != 0) {
            // 金额处理
            if (
              field === 'total_cash_amount' ||
              field === 'cash_amount' ||
              field === 'iaa_cash_amount' ||
              field === 'cpm_cash_amount'
            ) {
              if (item.data != '-') {
                item.data = Pub.KEEP_NUMBER_DECIMAL(item.data / 100, 2, false, true)
              }
              return item
            } else if (field === 'reseller') {
              item.data = json.reseller && json.reseller.name
              return item
            } else if (field === 'project') {
              item.data = json.project && json.project.name || '-'
              return item
            } else if (field === 'cycle') {
              item.data = `${json.start_time}至${json.end_time}`
              return item
            }
          }
          // 无数据兼容
          // item.data = item.data || 0
          return item
        }, '分销结算单-处理中')
      } else {
        message.error('还没有可导出的内容')
      }
    } else {
      message.error('导出失败')
    }
  }).catch((err) => {
    console.log(err)
    message.error('导出失败')
  })
}
</script>

<style scoped>
.tool-view {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}

</style>